.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    background-color: #f0f2f5;
}

.loginForm {
    max-width: 300px;
}

.loginForm .antFormItem {
    margin-bottom: 16px;
}

.loginForm .antFormItemIcon {
    color: rgba(0, 0, 0, 0.25);
}

.loginFormButton {
    width: 100%;
}